<script>
export default {
  name: "Yumpu",
  props: ['embed_id'],
  data() {
    return {
      map: null,
      active_parcel: null,
    }
  },
  mounted() {
    const vm = this;
    (async () => {
      await initializeAfterScriptLoad.call(this);
      const myYumpu = new Yumpu();
      myYumpu.create_player("#yumpuMagazineContainer", "", { lang:"en", embed_id: vm.embed_id, embedded:true } );
    })();
  },

  render() {
    return (
      <div class="yumpu">
        <div id="yumpuMagazineContainer"></div>
      </div>
    )
  }
};

function initializeAfterScriptLoad() {
  loadScript();
  return new Promise(resolve => {
    const check = () => {
      if (window.Yumpu) {resolve()}
      else setTimeout(check, 300)
    }
    check()
  })

  function loadScript() {
    if (document.head.querySelector("#yumpu")) return
    const script = document.createElement("script")
    script.setAttribute('id', "yumpu")
    script.src = "https://players.yumpu.com/hub.js"
    document.head.appendChild(script);
  }

}
</script>

<style lang="scss">
#yumpuMagazineContainer {
  width: 100%;
  height: calc(100vh - 100px);
  background-color: #FEFDEB;
}
</style>
