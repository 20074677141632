<template>
  <Yumpu :embed_id="$lang === 'hr' ? '64733777' : '64733777'" />
</template>

<script>
import Yumpu from "@/components/Elements/Yumpu"
export default {
  name: "LocksmithExplained",
  components: {Yumpu}
}
</script>

<style scoped>

</style>